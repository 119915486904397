<template>
  <v-row>
    <v-dialog v-model="dialogLoading" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Loading...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-col cols="12" md="3">
      <test-card
        chip-color="info lighten-5"
        title="Gross Sales"
        :amount="grossSales"
        footer-text="In Today"
        footer-class="info--text"
        card-icon="mdi-chart-bar"
        color-icon="info--text"
      />
    </v-col>
    <v-col cols="12" md="3">
      <test-card
        chip-color="primary lighten-5"
        title="Total Sales"
        :amount="dailySales"
        footer-text="In Today"
        footer-class="primary--text"
        card-icon="mdi-chart-line-variant"
        color-icon="primary--text"
      />
    </v-col>
    <v-col cols="12" md="3">
      <test-card
        chip-color="success lighten-5"
        title="Total Sales"
        :amount="weeklySales"
        footer-text="In this week"
        footer-class="success--text"
        card-icon="mdi-format-list-bulleted"
        color-icon="success--text"
      />
    </v-col>
    <v-col cols="12" md="3">
      <test-card
        chip-color="info lighten-5"
        title="Total Sales"
        :amount="monthlySales"
        footer-text="In this month"
        footer-class="info--text"
        card-icon="mdi-account-multiple"
        color-icon="info--text"
      />
    </v-col>
    <v-col cols="12">
      <base-card>
        <v-row>
          <v-col cols="8"><v-card-title>Sales Chart</v-card-title></v-col>
          <v-col cols="3"
            ><v-select
              class="mt-3 ml-10"
              v-model="range"
              :items="options"
              solo
              background-color="grey lighten-4"
              dense
            ></v-select
          ></v-col>
          <v-col cols="1">
            <v-dialog
              ref="dialog"
              v-model="modal"
              :return-value.sync="date"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  v-show="showIcon"
                  @click="date = []"
                  v-on="on"
                  class="mt-3"
                  icon
                >
                  <v-icon>mdi-calendar</v-icon>
                </v-btn>
              </template>
              <v-date-picker v-model="date" range scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="$refs.dialog.save(date)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
        </v-row>

        <v-card-text>{{ betweenDate }}</v-card-text>
        <v-row>
          <v-col>
            <div v-show="showChart">
              <apexchart :options="chartOptions" :series="series" />
            </div>
          </v-col>
        </v-row>
      </base-card>
    </v-col>
    <v-col cols="12">
      <base-card>
        <div class="d-flex align-center justify-space-between pr-3">
          <v-card-title>Sales Table</v-card-title>
          <v-spacer></v-spacer>
          <div v-if="filterList.length != 0">
            <export-excel
              :data="filterList"
              :fields="json_fields"
              :name="filename"
            >
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on"> mdi-download </v-icon>
                </template>
                <span>Download the Overall Sales Report</span>
              </v-tooltip>
            </export-excel>
          </div>
        </div>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="filterList"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            single-expand
            :expanded.sync="expanded"
            show-expand
            @page-count="pageCount = $event"
            item-key="settlement_id"
            hide-default-footer
            @item-expanded="handleClick"
          >
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <div class="ma-5">
                  <div>
                    <v-col>
                      <export-excel
                        :data="billList"
                        :fields="bill_fields"
                        :name="'Settlement_id_' + item.settlement_id + '.xls'"
                      >
                        <v-tooltip left>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on">
                              mdi-download
                            </v-icon>
                          </template>
                          <span>Download the Bill Sales Report</span>
                        </v-tooltip>
                      </export-excel>
                    </v-col>
                  </div>
                  <v-data-table
                    :headers="billHeaders"
                    :items="billList"
                    item-key="order_id"
                    @click:row="rowClick"
                  >
                    <template v-slot:item.tax_amount="{ item }">
                      <span v-if="item.tax_amount != null">{{
                        item.tax_amount
                      }}</span>
                      <span v-else>0.00</span>
                    </template>
                    <template v-slot:item.promotion_amount="{ item }">
                      <span v-if="item.promotion_amount == null">0.00</span>
                      <span v-else>{{ item.promotion_amount }}</span>
                    </template>

                    <template v-slot:item.refund_key="{ item }">
                      <span v-if="item.refund_key != ''">Yes</span>
                      <span v-else-if="item.refund_key == ''">No</span>
                    </template>

                    <template v-slot:item.refund_by="{ item }">
                      <span v-if="item.refund_by == null">-</span>
                      <span v-else>{{ item.refund_by }}</span>
                    </template>
                  </v-data-table>
                </div>
              </td>
            </template>
          </v-data-table>
          <div>
            <VueHtml2pdf
              :show-layout="false"
              :float-layout="true"
              :enable-download="false"
              :preview-modal="true"
              :paginate-elements-by-height="1100"
              filename="myPDF"
              :pdf-quality="2"
              :manual-pagination="false"
              pdf-format="a4"
              pdf-orientation="portrait"
              pdf-content-width="800px"
              ref="html2Pdf"
            >
              <section slot="pdf-content" style="padding: 20px">
                <h4 class="user-name">Order Number: {{ orderNo }}</h4>
                <p
                  style="
                    font-size: large;
                    margin-bottom: 30px;
                    margin-top: 10px;
                  "
                >
                  Dining Method: {{ diningMethod }}
                  <br />
                  Payment Method: {{ paymentMethod }}
                  <br />
                  Order at: {{ orderAt }}
                  <br />
                  Close By: {{ closeBy }}
                </p>
                <table id="customers">
                  <tr>
                    <th>Product</th>
                    <th>Product Variant</th>
                    <th>Categories</th>
                    <th>Quantity</th>
                    <th>Price</th>
                  </tr>
                  <tr
                    v-for="item in individualBill"
                    :key="item.order_detail_id"
                  >
                    <td>{{ item.product_name }}</td>
                    <td>
                      {{
                        item.product_variant_name
                          ? item.product_variant_name
                          : "-"
                      }}
                    </td>
                    <td>
                      {{ item.category_name ? item.category_name : "-" }}
                    </td>
                    <td>
                      {{ item.quantity }}
                    </td>
                    <td>
                      {{ item.price }}
                    </td>
                  </tr>
                  <tr>
                    <td colspan="3"></td>

                    <td
                      style="border-right: none !important; text-align: right"
                    >
                      Subtotal:
                    </td>
                    <td style="border-left: none !important">{{ subtotal }}</td>
                  </tr>
                  <tr>
                    <td colspan="3"></td>

                    <td
                      style="border-right: none !important; text-align: right"
                    >
                      Rounding:
                    </td>
                    <td style="border-left: none !important">{{ rounding }}</td>
                  </tr>
                  <tr>
                    <td colspan="3"></td>

                    <td
                      style="
                        border-right: none !important;
                        text-align: right;
                        font-weight: bold;
                      "
                    >
                      Total:
                    </td>
                    <td style="border-left: none !important; font-weight: bold">
                      {{ total }}
                    </td>
                  </tr>
                </table>
              </section>
            </VueHtml2pdf>
          </div>
          <div class="text-center py-2">
            <v-pagination v-model="page" :length="pageCount"></v-pagination>
          </div>
        </v-card-text>
      </base-card>
    </v-col>
  </v-row>
</template>
<script>
import { BASEURL } from "@/api/baseurl";
import axios from "axios";
import TestCard from "@/components/card/TestCard";
import PriceCard from "@/components/card/PriceCard";
import VueHtml2pdf from "vue-html2pdf";

export default {
  name: "Sales",
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "Sales",
  },
  components: {
    "test-card": TestCard,
    "price-card": PriceCard,
    VueHtml2pdf,
  },
  data() {
    return {
      json_fields: {
        'Settlement ID': 'settlement_id',
        'Branch': 'name',
        'Total Bill': 'total_bill',
        // "Item Cancelled": "total_cancellation",
        "Total Refund Bill": "total_refund_bill",
        "Total Refund Amount": "total_refund_amount",
        "Total Sales": "total_sales",
        "Discount amount": "total_discount",
        "Total Tax": "total_tax",
        "Opening At" : "opened_at",
        "Closing At": "created_at",
      },
      bill_fields: {
        "Order number(#)": "order_number",
        "Dining Method": "dining_name",
        "Payment Method": "name",
        "Tax Amount": {
          field: "tax_amount",
          callback: (value) => {
            if (value != "") {
              return value;
            } else {
              return "0.00";
            }
          },
        },
        "Discount Amount": {
          field: "promotion_amount",
          callback: (value) => {
            if (value != "") {
              return value;
            } else {
              return "0.00";
            }
          },
        },
        "Total Sales": "final_amount",
        "Close by": "close_by",
        Refund: {
          field: "refund_key",
          callback: (value) => {
            if (value != "") {
              return "Yes";
            } else {
              return "No";
            }
          },
        },
        "Refund By": {
          field: "refund_by",
          callback: (value) => {
            if (value != "") {
              return value;
            } else {
              return "-";
            }
          },
        },
        "Order at": "created_at",
      },
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
      domain: BASEURL,
      company_id: "",
      dailySales: "0.00",
      showIcon: false,
      showChart: false,
      date: [],
      modal: false,
      dialogLoading: false,
      weeklySales: "",
      monthlySales: "",
      grossSales: "",
      settlementList: "",
      orderNo: "",
      diningMethod: "",
      paymentMethod: "",
      closeBy: "",
      orderAt: "",
      subtotal: "",
      rounding: "",
      total: "",
      betweenDate: "",
      branchList: [],
      filterList: [],
      expanded: [],
      billList: [],
      individualBill: [],
      range: "Today",
      headers: [
        {
          text: "ID(#)",
          align: "start",
          sortable: false,
          value: "settlement_id",
        },

        {
          text: "Branch",
          align: "start",
          sortable: false,
          value: "name",
        },

        {
          text: "Total Bill",
          align: "start",
          sortable: false,
          value: "total_bill",
        },
        // {
        //   text: "Item Cancelled",
        //   align: "start",
        //   value: "total_cancellation",
        //   sortable: false,
        // },

        {
          text: "Total Refund Bill",
          align: "start",
          value: "total_refund_bill",
          sortable: false,
        },

        {
          text: "Total Refund Amount",
          align: "start",
          value: "total_refund_amount",
          sortable: false,
        },

        {
          text: "Total Sales",
          align: "start",
          value: "total_sales",
          sortable: false,
        },

        {
          text: "Discount Amount",
          align: "start",
          value: "total_discount",
          sortable: false,
        },

        {
          text: "Total Tax",
          align: "start",
          sortable: false,
          value: "total_tax",
        },

        {
          text: "Opening At",
          align: "start",
          value: "opened_at",
          sortable: true,
        },

        {
          text: "Closing At",
          align: "start",
          value: "created_at",
          sortable: true,
        },
        { text: "", value: "data-table-expand" },
      ],
      billHeaders: [
        {
          text: "Order number(#)",
          align: "start",
          sortable: false,
          value: "order_number",
        },

        {
          text: "Dining Method",
          align: "start",
          sortable: false,
          value: "dining_name",
        },

        {
          text: "Payment Method",
          align: "start",
          sortable: false,
          value: "name",
        },

        {
          text: "Tax amount",
          align: "start",
          value: "tax_amount",
          sortable: false,
        },

        {
          text: "Discount amount",
          align: "start",
          value: "promotion_amount",
          sortable: false,
        },

        {
          text: "Total Sales",
          align: "start",
          value: "final_amount",
          sortable: false,
        },

        {
          text: "Close by",
          align: "start",
          sortable: false,
          value: "close_by",
        },

        {
          text: "Refund",
          align: "start",
          sortable: false,
          value: "refund_key",
        },

        {
          text: "Refund by",
          align: "start",
          sortable: false,
          value: "refund_by",
        },

        {
          text: "Order at",
          align: "start",
          sortable: false,
          value: "created_at",
        },
      ],
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      options: [
        "Today",
        "Last 3 Days",
        "This Week",
        "Last 2 weeks",
        "This Month",
        "Last 3 Months",
        "This Year",
        "Custom Range",
      ],

      //chart
      series: [
        {
          name: "Sales",
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          height: 300,
          width: 600,
          type: "line",
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          type: "Date/Month/Year",
        },
      },
    };
  },
  computed: {
    filename() {
      if (this.range == "Custom Range") {
        return this.betweenDate + " Sales Report.xls";
      } else {
        return this.range + " Sales Report.xls";
      }
    },
    getTotal() {
      var total = 0;
      for (let i = 0; i < this.individualBill.length; i++) {
        total += parseFloat(this.individualBill[i].price);
      }
      return total.toFixed(2).toString();
    },
  },
  watch: {
    range() {
      this.betweenDate = "";
      this.filterList = [];
      this.showIcon = false;
      var moment = require("moment");
      var startWeek = moment().startOf("week");
      var endWeek = moment().endOf("week");
      var day = moment().format("DD-MM-YYYY");
      var year = moment().format("YYYY");
      var month = moment().format("MM");
      this.series = [
        {
          name: "Sales",
          data: [],
        },
      ];

      if (this.range == "Today") {
        var obj = {
          x: moment().format("DD MMM"),
          y: this.dailySales,
        };
        this.series[0].data.push(obj);
        for (let j = 0; j < this.settlementList.length; j++) {
          if (
            moment(this.settlementList[j].created_at).format("DD-MM-YYYY") ==
            day
          ) {
            this.filterList.push(this.settlementList[j]);
          }
        }
      } else if (this.range == "Last 3 Days") {
        for (let j = 0; j < this.settlementList.length; j++) {
          if (
            moment(this.settlementList[j].created_at).isBetween(
              moment().subtract(3, "days"),
              moment(),
              undefined,
              "[)"
            )
          ) {
            this.filterList.push(this.settlementList[j]);
          }
        }
        // count date
        var date = [];
        var num = 3;
        while (num >= 1) {
          var tempDate = moment().subtract(num, "days");
          date.push(moment(tempDate));
          num--;
        }

        // assign data
        for (let o = 0; o < date.length; o++) {
          var obj = { x: "", y: "" };
          if (this.filterList.length == 0) {
            obj.x = moment(date[o]).format("DD MMM");
            obj.y = 0.0;
            this.series[0].data.push(obj);
          } else {
            var sales = 0.0;
            for (let l = 0; l < this.filterList.length; l++) {
              if (
                moment(date[o]).format("DD-MM-YYYY") ==
                moment(this.filterList[l].created_at).format("DD-MM-YYYY")
              ) {
                sales += parseFloat(this.filterList[l].total_sales);
              }
            }
            obj.x = moment(date[o]).format("DD MMM");
            obj.y = sales.toFixed(2).toString();
            this.series[0].data.push(obj);
          }
        }
      } else if (this.range == "This Week") {
        for (let j = 0; j < this.settlementList.length; j++) {
          if (
            moment(this.settlementList[j].created_at).isBetween(
              moment(startWeek).add(1, "days"),
              moment(endWeek).add(1, "days"),
              undefined,
              "[]"
            )
          ) {
            this.filterList.push(this.settlementList[j]);
          }
        }

        // count date
        var date = [];
        var num = 1;
        while (num <= 7) {
          var tempDate = moment(startWeek).add(num, "days");
          date.push(moment(tempDate));
          num++;
        }

        // assign data
        for (let o = 0; o < date.length; o++) {
          var obj = { x: "", y: "" };
          if (this.filterList.length == 0) {
            obj.x = moment(date[o]).format("DD MMM");
            obj.y = 0.0;
            this.series[0].data.push(obj);
          } else {
            var sales = 0.0;
            for (let l = 0; l < this.filterList.length; l++) {
              if (
                moment(date[o]).format("DD-MM-YYYY") ==
                moment(this.filterList[l].created_at).format("DD-MM-YYYY")
              ) {
                sales += parseFloat(this.filterList[l].total_sales);
              }
            }
            obj.x = moment(date[o]).format("DD MMM");
            obj.y = sales.toFixed(2).toString();
            this.series[0].data.push(obj);
          }
        }
      } else if (this.range == "Last 2 weeks") {
        var start2Week = moment(startWeek).subtract(14, "days");
        for (let j = 0; j < this.settlementList.length; j++) {
          if (
            moment(this.settlementList[j].created_at).isBetween(
              moment(start2Week).add(1, "days"),
              moment(start2Week).add(15, "days"),
              undefined,
              "[]"
            )
          ) {
            this.filterList.push(this.settlementList[j]);
          }
        }

        // count date
        var date = [];
        var num = 1;
        while (num <= 14) {
          var tempDate = moment(start2Week).add(num, "days");
          date.push(moment(tempDate));
          num++;
        }

        // assign data
        for (let o = 0; o < date.length; o++) {
          var obj = { x: "", y: "" };
          if (this.filterList.length == 0) {
            obj.x = moment(date[o]).format("DD MMM");
            obj.y = 0.0;
            this.series[0].data.push(obj);
          } else {
            var sales = 0.0;
            for (let l = 0; l < this.filterList.length; l++) {
              if (
                moment(date[o]).format("DD-MM-YYYY") ===
                moment(this.filterList[l].created_at).format("DD-MM-YYYY")
              ) {
                sales += parseFloat(this.filterList[l].total_sales);
              }
            }
            obj.x = moment(date[o]).format("DD MMM");
            obj.y = sales.toFixed(2).toString();
            this.series[0].data.push(obj);
          }
        }
      } else if (this.range == "This Month") {
        for (let j = 0; j < this.settlementList.length; j++) {
          if (moment(this.settlementList[j].created_at).format("MM") == month) {
            this.filterList.push(this.settlementList[j]);
          }
        }

        // count date
        var tempDate = moment().startOf("month");
        var startMonth = moment(tempDate).subtract(1, "days");
        var date = [];
        var num = 1;
        while (num < moment(startMonth).daysInMonth()) {
          var tempDate = moment(startMonth).add(num, "days");
          date.push(moment(tempDate));
          num++;
        }

        // assign data
        for (let o = 0; o < date.length; o++) {
          var obj = { x: "", y: "" };
          if (this.filterList.length == 0) {
            obj.x = moment(date[o]).format("DD MMM");
            obj.y = 0.0;
            this.series[0].data.push(obj);
          } else {
            var sales = 0.0;
            for (let l = 0; l < this.filterList.length; l++) {
              if (
                moment(date[o]).format("DD-MM-YYYY") ===
                moment(this.filterList[l].created_at).format("DD-MM-YYYY")
              ) {
                sales += parseFloat(this.filterList[l].total_sales);
              }
            }
            obj.x = moment(date[o]).format("DD MMM");
            obj.y = sales.toFixed(2).toString();
            this.series[0].data.push(obj);
          }
        }
      } else if (this.range == "Last 3 Months") {
        var startThisMonth = moment().startOf("month");
        var lastThreeMonth = moment(startThisMonth).subtract(3, "months");
        var tempMonth = moment(lastThreeMonth).add(2, "months");

        for (let j = 0; j < this.settlementList.length; j++) {
          if (
            moment(this.settlementList[j].created_at).isBetween(
              lastThreeMonth,
              moment(tempMonth).endOf("month")
            )
          ) {
            this.filterList.push(this.settlementList[j]);
          }
        }

        // count date
        var date = [];
        var num = 3;
        while (num >= 1) {
          var tempDate = moment(startThisMonth).subtract(num, "months");
          date.push(moment(tempDate));
          num--;
        }

        // assign data
        for (let o = 0; o < date.length; o++) {
          var obj = { x: "", y: "" };
          if (this.filterList.length == 0) {
            obj.x = moment(date[o]).format("MMM");
            obj.y = 0.0;
            this.series[0].data.push(obj);
          } else {
            var sales = 0.0;
            for (let l = 0; l < this.filterList.length; l++) {
              if (
                moment(date[o]).format("MM-YYYY") ===
                moment(this.filterList[l].created_at).format("MM-YYYY")
              ) {
                sales += parseFloat(this.filterList[l].total_sales);
              }
            }
            obj.x = moment(date[o]).format("MMM");
            obj.y = sales.toFixed(2).toString();
            this.series[0].data.push(obj);
          }
        }
      } else if (this.range == "This Year") {
        for (let j = 0; j < this.settlementList.length; j++) {
          if (
            moment(this.settlementList[j].created_at).format("YYYY") == year
          ) {
            this.filterList.push(this.settlementList[j]);
          }
        }

        // count date
        var yearMonth = [
          "01",
          "02",
          "03",
          "04",
          "05",
          "06",
          "07",
          "08",
          "09",
          "10",
          "11",
          "12",
        ];
        var date = [];
        for (let m = 0; m < yearMonth.length; m++) {
          var temp = moment(yearMonth[m] + "-" + year, "MM-YYYY");
          date.push(temp);
        }
        // assign data
        for (let o = 0; o < date.length; o++) {
          var obj = { x: "", y: "" };
          if (this.filterList.length == 0) {
            obj.x = moment(date[o]).format("MMM");
            obj.y = 0.0;
            this.series[0].data.push(obj);
          } else {
            var sales = 0.0;
            for (let l = 0; l < this.filterList.length; l++) {
              if (
                moment(date[o]).format("MM-YYYY") ===
                moment(this.filterList[l].created_at).format("MM-YYYY")
              ) {
                sales += parseFloat(this.filterList[l].total_sales);
              }
            }
            obj.x = moment(date[o]).format("MMM");
            obj.y = sales.toFixed(2).toString();
            this.series[0].data.push(obj);
          }
        }
      } else if (this.range == "Custom Range") {
        this.showIcon = true;
      }
    },
    date() {
      var moment = require("moment");
      var temValue = "";
      this.filterList = [];
      this.betweenDate = "";
      this.series = [
        {
          name: "Sales",
          data: [],
        },
      ];
      if (this.date.length > 1) {
        if (moment(this.date[0]).isBefore(moment(this.date[1]))) {
          this.betweenDate = "Date: " + this.date[0] + " - " + this.date[1];
          for (let j = 0; j < this.settlementList.length; j++) {
            temValue = moment(this.settlementList[j].created_at).format(
              "YYYY-MM-DD"
            );
            if (
              moment(temValue).isBetween(
                moment(this.date[0]),
                moment(this.date[1]),
                undefined,
                "[]"
              )
            ) {
              this.filterList.push(this.settlementList[j]);
            }
          }

          var x = new moment(this.date[0]);
          var y = new moment(this.date[1]);
          var duration = y.diff(x, "days");
          var monthDuration = y.diff(x, "months");
          var yearDuration = y.diff(x, "years");

          if (duration <= 31) {
            // count date
            var tempDay = [];
            var init = 1;
            var num = duration;
            tempDay.push(moment(this.date[0]));
            while (init <= num) {
              var tempDate = moment(this.date[0]).add(init, "days");
              tempDay.push(moment(tempDate));
              init++;
            }
            // assign data
            for (let o = 0; o < tempDay.length; o++) {
              var obj = { x: "", y: "" };
              if (this.filterList.length == 0) {
                obj.x = moment(tempDay[o]).format("DD MMM");
                obj.y = 0.0;
                this.series[0].data.push(obj);
              } else {
                var sales = 0.0;
                for (let l = 0; l < this.filterList.length; l++) {
                  if (
                    moment(tempDay[o]).format("DD-MM-YYYY") ==
                    moment(this.filterList[l].created_at).format("DD-MM-YYYY")
                  ) {
                    sales += parseFloat(this.filterList[l].total_sales);
                  }
                }
                obj.x = moment(tempDay[o]).format("DD MMM");
                obj.y = sales.toFixed(2).toString();
                this.series[0].data.push(obj);
              }
            }
          } else if (duration > 31 && duration <= 365) {
            // count month
            var tempMonth = [];
            var num = monthDuration;
            var tempNum = 1;
            tempMonth.push(moment(x));
            while (tempNum < num) {
              var tempDateMonth = moment(x).add(tempNum, "months");
              tempMonth.push(moment(tempDateMonth));
              tempNum++;
            }
            tempMonth.push(moment(y));

            for (let o = 0; o < tempMonth.length; o++) {
              var obj = { x: "", y: "" };
              if (this.filterList.length == 0) {
                obj.x = moment(tempMonth[o]).format("MMM");
                obj.y = 0.0;
                this.series[0].data.push(obj);
              } else {
                var sales = 0.0;
                for (let l = 0; l < this.filterList.length; l++) {
                  if (
                    moment(tempMonth[o]).format("MM-YYYY") ==
                    moment(this.filterList[l].created_at).format("MM-YYYY")
                  ) {
                    sales += parseFloat(this.filterList[l].total_sales);
                  }
                }
                obj.x = moment(tempMonth[o]).format("MMM");
                obj.y = sales.toFixed(2).toString();
                this.series[0].data.push(obj);
              }
            }
          } else if (duration > 365) {
            // count year
            var tempYear = [];
            var num = yearDuration;
            var tempNum = 1;

            var tempDateYear = moment(x).add(num, "years");

            tempYear.push(moment(x));
            while (tempNum <= num) {
              var tempDateYear = moment(x).add(tempNum, "years");
              tempYear.push(moment(tempDateYear));
              tempNum++;
            }
            for (let o = 0; o < tempYear.length; o++) {
              var obj = { x: "", y: "" };
              if (this.filterList.length == 0) {
                obj.x = moment(tempYear[o]).format("YYYY");
                obj.y = 0.0;
                this.series[0].data.push(obj);
              } else {
                var sales = 0.0;
                for (let l = 0; l < this.filterList.length; l++) {
                  if (
                    moment(tempYear[o]).format("MM-YYYY") ==
                    moment(this.filterList[l].created_at).format("MM-YYYY")
                  ) {
                    sales += parseFloat(this.filterList[l].total_sales);
                  }
                }
                obj.x = moment(tempYear[o]).format("YYYY");
                obj.y = sales.toFixed(2).toString();
                this.series[0].data.push(obj);
              }
            }
          }
        } else if (moment(this.date[1]).isBefore(moment(this.date[0]))) {
          this.betweenDate = "Date: " + this.date[1] + " - " + this.date[0];

          for (let j = 0; j < this.settlementList.length; j++) {
            temValue = moment(this.settlementList[j].created_at).format(
              "YYYY-MM-DD"
            );
            if (
              moment(temValue).isBetween(
                moment(this.date[1]),
                moment(this.date[0]),
                undefined,
                "[]"
              )
            ) {
              this.filterList.push(this.settlementList[j]);
            }
          }

          var x = new moment(this.date[1]);
          var y = new moment(this.date[0]);
          var duration = y.diff(x, "days");
          var monthDuration = y.diff(x, "months");
          var yearDuration = y.diff(x, "years");

          if (duration <= 31) {
            // count date
            var tempDay = [];
            var init = 1;
            var num = duration;
            tempDay.push(moment(this.date[0]));
            while (init <= num) {
              var tempDate = moment(this.date[0]).add(init, "days");
              tempDay.push(moment(tempDate));
              init++;
            }
            // assign data
            for (let o = 0; o < tempDay.length; o++) {
              var obj = { x: "", y: "" };
              if (this.filterList.length == 0) {
                obj.x = moment(tempDay[o]).format("DD MMM");
                obj.y = 0.0;
                this.series[0].data.push(obj);
              } else {
                var sales = 0.0;
                for (let l = 0; l < this.filterList.length; l++) {
                  if (
                    moment(tempDay[o]).format("DD-MM-YYYY") ==
                    moment(this.filterList[l].created_at).format("DD-MM-YYYY")
                  ) {
                    sales += parseFloat(this.filterList[l].total_sales);
                  }
                }
                obj.x = moment(tempDay[o]).format("DD MMM");
                obj.y = sales.toFixed(2).toString();
                this.series[0].data.push(obj);
              }
            }
          } else if (duration > 31 && duration <= 365) {
            // count month
            var tempMonth = [];
            var num = monthDuration;
            var tempNum = 1;
            tempMonth.push(moment(x));
            while (tempNum < num) {
              var tempDateMonth = moment(x).add(tempNum, "months");
              tempMonth.push(moment(tempDateMonth));
              tempNum++;
            }
            tempMonth.push(moment(y));

            for (let o = 0; o < tempMonth.length; o++) {
              var obj = { x: "", y: "" };
              if (this.filterList.length == 0) {
                obj.x = moment(tempMonth[o]).format("MMM");
                obj.y = 0.0;
                this.series[0].data.push(obj);
              } else {
                var sales = 0.0;
                for (let l = 0; l < this.filterList.length; l++) {
                  if (
                    moment(tempMonth[o]).format("MM-YYYY") ==
                    moment(this.filterList[l].created_at).format("MM-YYYY")
                  ) {
                    sales += parseFloat(this.filterList[l].total_sales);
                  }
                }
                obj.x = moment(tempMonth[o]).format("MMM");
                obj.y = sales.toFixed(2).toString();
                this.series[0].data.push(obj);
              }
            }
          } else if (duration > 365) {
            // count year
            var tempYear = [];
            var num = yearDuration;
            var tempNum = 1;

            var tempDateYear = moment(x).add(num, "years");

            tempYear.push(moment(x));
            while (tempNum <= num) {
              var tempDateYear = moment(x).add(tempNum, "years");
              tempYear.push(moment(tempDateYear));
              tempNum++;
            }
            for (let o = 0; o < tempYear.length; o++) {
              var obj = { x: "", y: "" };
              if (this.filterList.length == 0) {
                obj.x = moment(tempYear[o]).format("YYYY");
                obj.y = 0.0;
                this.series[0].data.push(obj);
              } else {
                var sales = 0.0;
                for (let l = 0; l < this.filterList.length; l++) {
                  if (
                    moment(tempYear[o]).format("MM-YYYY") ==
                    moment(this.filterList[l].created_at).format("MM-YYYY")
                  ) {
                    sales += parseFloat(this.filterList[l].total_sales);
                  }
                }
                obj.x = moment(tempYear[o]).format("YYYY");
                obj.y = sales.toFixed(2).toString();
                this.series[0].data.push(obj);
              }
            }
          }
        } else if (moment(this.date[1]).isSame(moment(this.date[0]))) {
          this.betweenDate = "Date: " + this.date[0];
          var selectedDay = moment(this.date[0]).format("DD MMM");
          for (let j = 0; j < this.settlementList.length; j++) {
            if (
              moment(this.settlementList[j].created_at).format("YYYY-MM-DD") ==
              this.date[0]
            ) {
              this.filterList.push(this.settlementList[j]);
            }
          }
          // assign data
          var obj = { x: "", y: "" };
          if (this.filterList.length == 0) {
            obj.x = selectedDay;
            obj.y = 0.0;
            this.series[0].data.push(obj);
          } else {
            var sales = 0.0;
            for (let l = 0; l < this.filterList.length; l++) {
              if (
                moment(this.date[0]).format("MM-YYYY") ===
                moment(this.filterList[l].created_at).format("MM-YYYY")
              ) {
                sales += parseFloat(this.filterList[l].total_sales);
              }
            }
            obj.x = selectedDay;
            obj.y = sales.toFixed(2).toString();
            this.series[0].data.push(obj);
          }
        }
      } else {
        if (this.date[0] != null) {
          this.betweenDate = "Date: " + this.date[0];
        } else {
          this.betweenDate = "Date: Today";
          for (let j = 0; j < this.settlementList.length; j++) {
            if (
              moment(this.settlementList[j].created_at).format("DD-MM-YYYY") ==
              moment().format("DD-MM-YYYY")
            ) {
              this.filterList.push(this.settlementList[j]);
            }
          }
        }
        var selectedDay = moment(this.date[0]).format("DD MMM");
        for (let j = 0; j < this.settlementList.length; j++) {
          if (
            moment(this.settlementList[j].created_at).format("YYYY-MM-DD") ==
            this.date[0]
          ) {
            this.filterList.push(this.settlementList[j]);
          }
        }
        // assign data
        var obj = { x: "", y: "" };
        if (this.filterList.length == 0) {
          obj.x = selectedDay;
          obj.y = 0.0;
          this.series[0].data.push(obj);
        } else {
          var sales = 0.0;
          for (let l = 0; l < this.filterList.length; l++) {
            if (
              moment(this.date[0]).format("MM-YYYY") ===
              moment(this.filterList[l].created_at).format("MM-YYYY")
            ) {
              sales += parseFloat(this.filterList[l].total_sales);
            }
          }
          obj.x = selectedDay;
          obj.y = sales.toFixed(2).toString();
          this.series[0].data.push(obj);
        }
      }
    },
  },
  created() {
    var CryptoJS = require("crypto-js");
    var x = localStorage.getItem("userInfo");
    // Decrypt
    var bytes = CryptoJS.AES.decrypt(x, "secret key 123");
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    this.companyID = decryptedData.company_id;
    this.getAllSettlement();
  },
  methods: {
    getAllSettlement() {
      const params = new URLSearchParams();
      params.append("read", "done");
      params.append("company_id", this.companyID);

      axios({
        method: "post",
        url: this.domain + "/report/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            var moment = require("moment");
            var daySales = 0.0;
            var weekSales = 0.0;
            var monthSales = 0.0;
            var grossSale = 0.0;
            var startWeek = moment().startOf("week");
            var endWeek = moment().endOf("week");
            var month = moment().format("MM");
            var day = moment().format("DD-MM-YYYY");
            this.settlementList = response.data.report;
            for (let j = 0; j < this.settlementList.length; j++) {
              if (
                moment(this.settlementList[j].created_at).format("MM") == month
              ) {
                monthSales += parseFloat(this.settlementList[j].total_sales);
              }
              if (
                moment(this.settlementList[j].created_at).format(
                  "DD-MM-YYYY"
                ) == day
              ) {
                this.filterList.push(this.settlementList[j]);

                daySales += parseFloat(this.settlementList[j].total_sales);
                grossSale += parseFloat(
                  this.settlementList[j].total_sales -
                  this.settlementList[j].total_tax +
                  this.settlementList[j].total_discount
                  
                );
              }
              if (
                moment(this.settlementList[j].created_at).isBetween(
                  moment(startWeek).add(1, "days"),
                  moment(endWeek).add(1, "days"),
                  undefined,
                  "[]"
                )
              ) {
                weekSales += parseFloat(this.settlementList[j].total_sales);
              }
            }

            this.dailySales = daySales.toFixed(2).toString();
            this.weeklySales = weekSales.toFixed(2).toString();
            this.monthlySales = monthSales.toFixed(2).toString();
            this.grossSales = grossSale.toFixed(2).toString();

            var obj = {
              x: moment().format("DD MMM"),
              y: this.dailySales,
            };
            this.series[0].data.push(obj);

            this.showChart = true;
          } else {
            var moment = require("moment");
            this.dailySales = "0.00";
            this.weeklySales = "0.00";
            this.monthlySales = "0.00";
            this.grossSales = "0.00";
            var obj = {
              x: moment().format("DD MMM"),
              y: this.dailySales,
            };
            this.series[0].data.push(obj);

            this.showChart = true;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handleClick(value) {
      const params = new URLSearchParams();
      params.append("getBillDetail", "done");
      params.append("settlement_key", value.item.settlement_key);

      axios({
        method: "post",
        url: this.domain + "/report/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.billList = response.data.report;
          } else {
            this.billList = [];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    rowClick(value) {
      this.dialogLoading = true;
      this.orderNo = value.order_number;
      this.diningMethod = value.dining_name;
      this.paymentMethod = value.name;
      this.closeBy = value.close_by;
      this.orderAt = value.created_at;
      this.subtotal = value.amount;
      this.rounding = value.rounding;
      this.total = value.final_amount;

      const params = new URLSearchParams();
      params.append("getIndivisualBillDetail", "done");
      params.append("order_key", value.order_key);

      axios({
        method: "post",
        url: this.domain + "/report/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.dialogLoading = false;
            this.individualBill = response.data.report;
            this.$refs.html2Pdf.generatePdf();
          } else {
            this.dialogLoading = false;
            this.individualBill = [];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getCompanyBranch() {
      const params = new URLSearchParams();
      params.append("getCompanyBranch", "done");
      params.append("company_id", this.companyID);

      axios({
        method: "post",
        url: this.domain + "/branch/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.branchList = response.data.branch;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
<style>
#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  font-size: large;
}

#customers td,
#customers th {
  border: 1px solid #ddd;
  padding: 8px;
}

#customers tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers tr:hover {
  background-color: #ddd;
}

#customers th {
  padding-top: 6px;
  padding-bottom: 6px;
  text-align: left;
  background-color: #04aa6d;
  color: white;
}
</style>


